.pushable:not(body) {
    transform: none;
  }
  
  .pushable:not(body) > .ui.sidebar,
  .pushable:not(body) > .fixed,
  .pushable:not(body) > .pusher:after {
    position: fixed;
  }

  #root {
    height: 103%
  }

  input,
.StripeElement {

  margin: 10px 0 20px 0;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  border: solid 1px rgba(0,0,0, .15);
  border-radius: 4px;
}

input:focus,
.StripeElement--focus {
  border-color: #85B7D9; 
}

.answerCard .card{
  width: 100% !important;
}

.remove {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.remove button{
  position: absolute;
  top: -35px;
}

.freeTextField{
  z-index: 1;
  position: relative;
}

#videoTag .before-recording {
  background-color: black;
  height: 180px;  
  padding: 20px;
  display: block;
}

#videoTag .after-recording {
  width: 100%;
  min-height: 300px;
  padding: 20px;
  margin: 0 auto;
  display: block;
  max-height: 400px;
}

#videoTag .video {
  background: black;
}
.video-cta {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.button-card p{
  text-align: center;
    font-size: 2rem;
    font-weight: 600;
}

.welcomeTooltip{
  width: 500px;
}

.accordion .content.active {
  z-index: 99;
  position: relative;
}

.flex-grow{
  min-height: 400px !important;
  flex-grow: 1;
}

.record-presentation{
  box-sizing: border-box;
  height: 100%;
}

.record-presentation .card h2{
  font-size: 19px;
  margin-top: 13px;
}

.record-card {
  margin-top: 30px 
}

.record-card .casual-encounter{
  color: #186d9a;
}

.record-card .sales-presentation{
  color: #11244f;
}

.record-card .phone-call {
  color: #3c3c3c;
}

.record-presentation .card .recording-card-subheading{
  font-size: 14px;
  line-height: 1.3;
}

.record-presentation .card .recording-card-icon{
  width: 40px;
}

.start-recording-button {
  cursor: pointer;
  padding: 14px 24px;
  border-radius: 4px !important;
  font-size: 16px;
  border: none;
  text-wrap: nowrap;
  color: white;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-left: auto;
  margin-right: auto;
  &:disabled{
    cursor: not-allowed !important;
  }
}

.record-card .start-recording-button.btn-casual{
  background: #186d9a;
   &:disabled{
    background-color: #186d9785  !important;
  }
}

.record-card .start-recording-button.btn-sales{
  background: #11244f;
  &:disabled{
    background-color: #11244fa1  !important;
  }
}

.record-card .start-recording-button.btn-phone{
  background: #3c3c3c;
  &:disabled{
    background-color: #3c3c3c8c  !important;
  }
}

.live-video-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #202126;  
  border-radius: 15px;
  max-width: 800px;
}

.live-video-card .component{
  background: #2b3033;
  padding: 7px;
}

.live-video-card .timmer{
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.live-video-card .timmer p{
  background: white;
    padding: 2px;
    width: 21%;
    font-size: 16px;
    border-radius: 5px;
    font-weight: 800;
}

.live-video-card .button{
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.live-video-card .video-component{
  display: grid;
  grid-template-columns: 49% 49%;
  grid-column-gap: 2%;
  grid-row-gap: 20px;
  padding: 20px;
}

.live-video-card .video-component > div, .live-video-card .video-component > video {
  width: 100%;
  margin: 0;
}

.live-video-card .common-box{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-weight: 600;  
  height: 180px;
  background: #d9d9d9;
}

.live-video-card .box{ 
  width: 48%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.live-video-card .box img {
  width: 94%;
  height: 88%;
}

.live-video-card .left-comp{
  margin-left: 30px;
}

.live-video-card .right-comp{
  margin-right: 30px;
}

.start-stop-button {
  cursor: pointer;
  padding: 14px;
  width: 165px;
  border-radius: 50px;
  border: none;
  background: #616164;
  color: white;
}

/* .record-presentation-page {
  margin: auto 90px;
  background: white;
  border-radius: 10px;
  height: 100%;
  max-height: 85vh;
  padding: 15px;
  display: flex;
  flex-direction: column;
} */
.record-presentation-parent {
  min-height: initial !important;
}
.record-presentation-parent .before-recording {
  width: 100% !important;
}
.live-video-card .video-component .box.left-comp img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
