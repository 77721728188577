.angled-banner {
    position: absolute;
    top: 67px;
    right: -25px;
    background: #f2711c;
    /* Adjust to your desired color */
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    transform: rotate(45deg);
    transform-origin: top right;
    padding: 5px 20px;
    z-index: 10;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.angled-banner span {
    display: block;
    text-align: center;
    font-size: 12px;
    /* Adjust font size as needed */
}

.ui.card {
    position: relative;
    /* Ensure the banner is properly positioned relative to the card */
}