/*******************************
         Site Overrides
*******************************/

.ui.secondary.vertical.pointing.menu .item {
    border-right: none;
    border-bottom: none;
    border-left-style: solid;
    border-left-color: transparent;
    border-radius: 0em !important;
    margin: 0em -2px 0em 0em;
    border-left-width: 2px;
}